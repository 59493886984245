<script setup lang="ts">
import { pictureProps } from '#image/components/nuxt-picture'
import type { File } from '~/types/api'

interface VFullScreenMedia {
    image?: File | null
    src?: string
    pictureAttrs?: typeof pictureProps
}

const props = defineProps<VFullScreenMedia>()

const $style = useCssModule()
const commonAttrs = computed(() => {
    return {
        class: [$style.media, 'grid-item-full-edge'],
        sizes: 'sm:100vw xs:100vw md:100vw lg:100vw xl:100vw xxl:100vw hd:100vw',
        imgAttrs: { loading: 'eager' },
        width: '1440',
        height: '500',
        ...props.pictureAttrs,
    }
})

const hasApiFile = computed(() => {
    return props.image?.processable && props.image?.relativePath
})
</script>

<template>
    <div class="grid" :class="[$style.root, hasApiFile && $style['root--has-api-file']]">
        <NuxtPicture
            v-if="image && hasApiFile"
            v-bind="commonAttrs"
            :src="image.relativePath"
            :alt="image.title"
            provider="interventionRequest"
            :modifiers="{ crop: '1440x500', align: 'top' }"
        />
        <NuxtPicture v-else-if="src" v-bind="commonAttrs" :src="src" provider="ipx" quality="70" />
    </div>
</template>

<style lang="scss" module>
.root {
    position: absolute;
    z-index: -1;
    overflow: hidden;
    width: 100%;
    height: rem(500);
    inset: 0;
    pointer-events: none;
    mix-blend-mode: multiply;

    @include media('>=lg') {
        overflow: inherit;
    }
}

.media {
    grid-column: 1 / -1;
    user-select: none;

    .root--has-api-file & {
        mask-image: linear-gradient(to top, transparent 0%, black 100%);
        opacity: 0.12;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>
